import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Line } from 'app/components/Common/Line'
import { ChevronRight } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useEffect, useRef, useState } from 'react'

import { Props as SlideProps, Slide } from './Slide'

const AUTOPLAY_TIMER = 5000

export interface Props {
  languageCode: string
  offersButtonURL?: string
  slides: SlideProps[]
  title?: string
}

export const OffersSlider = memo(function OffersSlider({
  languageCode,
  offersButtonURL,
  slides,
  title,
}: Props) {
  if (slides.length < 1) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState(0)
  const [opacities, setOpacities] = React.useState<number[]>([])
  const timer = useRef<any>()

  const [sliderRef, instanceRef] = useKeenSlider({
    drag: false,
    loop: slides.length > 1 ? true : false,
    slides: slides.length,
    animationEnded(s) {
      setCurrentSlide(s.track.details.rel)
    },
    detailsChanged(s) {
      const newOpacities = s.track.details.slides.map((slide) => slide.portion)
      slides.length > 1 ? setOpacities(newOpacities) : null
    },
    breakpoints: {
      '(max-width: 767px)': {
        drag: true,
      },
    },
  })

  useEffect(() => {
    timer.current = setInterval(() => {
      if (instanceRef) {
        instanceRef.current?.next()
      }
    }, AUTOPLAY_TIMER)

    return () => {
      clearInterval(timer.current)
    }
  }, [currentSlide, instanceRef, sliderRef])

  return (
    <Container>
      <Title>
        {title ? (
          <FadeInUp>
            <Title>
              {title}
              <Line />
            </Title>
          </FadeInUp>
        ) : null}
      </Title>
      <Wrapper>
        {offersButtonURL ? (
          <CTA to={offersButtonURL}>
            {useVocabularyData('see-all-offers', languageCode)}
            <ChevronRight />
          </CTA>
        ) : null}

        <Slider ref={sliderRef}>
          {slides.map((item, index) => (
            <Slide
              key={index}
              style={{
                opacity: opacities[index],
                zIndex: opacities[index],
              }}
              {...item}
            />
          ))}
        </Slider>

        {slides.length > 1 ? (
          <Arrows row>
            <Arrow
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.prev()
              }
            />
            <Arrow
              direction="R"
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.next()
              }
            />
          </Arrows>
        ) : null}
        {slides.length > 1 ? (
          <Dots dial={2} row>
            {slides.map((item, index) => (
              <Dot
                key={index}
                className={currentSlide === index ? 'active' : undefined}
                onClick={() => {
                  instanceRef.current?.moveToIdx(index)
                }}
              />
            ))}
          </Dots>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 13.75rem 0;
  padding: 0 10.556vw;

  @media (max-width: 1199px) {
    margin: 9.375rem 0;
    padding: 0 1.875rem;
  }

  @media (max-width: 1023px) {
    margin: 7.5rem 0;
  }

  @media (max-width: 767px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.5rem;
  line-height: 4.5rem;

  > div {
    margin-top: 1.875rem;
  }

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.875rem;

    > div {
      margin-top: 1.25rem;
    }
  }
`

const Wrapper = styled.div`
  margin-top: 3.5625rem;
  position: relative;

  @media (max-width: 1023px) {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2.5rem;
  }
`

const CTA = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 500;
  line-height: 1.25rem;
  position: absolute;
  top: 0;
  right: 0;
  transition: 0.3s ease-in-out;
  z-index: 2;
  &:hover {
    color: ${({ theme }) => theme.colors.variants.neutralDark2};
    &:after {
      right: 0.3125rem;
    }
    svg {
      margin-left: 2.625rem;
      stroke: ${({ theme }) => theme.colors.variants.neutralDark2};
    }
  }
  &:after {
    content: '';
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark2};
    position: absolute;
    top: 0.625rem;
    right: 1.5625rem;
    left: calc(100% - 1.5625rem);
    transition: 0.3s 0.15s ease-in-out;
  }

  svg {
    width: auto;
    height: 1rem;
    fill: none;
    stroke: ${({ theme }) => theme.colors.variants.secondaryLight};
    stroke-width: 2;
    margin-left: 2.1875rem;
    transition: 0.3s ease-in-out;
    transform: translateY(0.0625rem);
  }

  @media (max-width: 1199px) {
    font-size: 1rem;
  }

  @media (max-width: 1023px) {
    order: 2;
    margin: 1.5rem auto 0;
    position: relative;
  }
`

const Slider = styled.div`
  display: flex;
  height: 75.1vh;
  min-height: 37.5rem;
  position: relative;

  @media (max-width: 1199px) {
    height: 70vh;
    min-height: 34.375rem;
  }

  @media (max-width: 1023px) {
    width: 100%;
    height: 31.25rem;
    min-height: 0;
  }
`

const Arrows = styled(FlexBox)`
  position: absolute;
  bottom: 0;
  left: calc(50% + 12.5vw + 2.5rem);
  z-index: 2;

  > div {
    &:last-of-type {
      margin-left: 2.5rem;
    }
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const Dots = styled(FlexBox)`
  position: absolute;
  bottom: 0.75rem;
  right: 0;
  z-index: 2;

  @media (max-width: 1023px) {
    width: 100%;
    order: 3;
    position: relative;
    bottom: auto;
    margin-top: 3rem;
  }
`

const Dot = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  background: ${({ theme }) => theme.colors.variants.secondaryLight};
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.4;
  margin-left: 1.25rem;
  &:first-of-type {
    margin-left: 0;
  }
  &.active {
    cursor: default;
    opacity: 1;
    pointer-events: none;
  }
`
