import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Line } from 'app/components/Common/Line'
import { IconLogo, Play } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo, useRef, useState } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  description?: string
  image?: ImageProps
  URL: string
}

export const Video = memo(function Video({ description, image, URL }: Props) {
  if (!URL) {
    return null
  }

  const videoRef = useRef(null)
  const [videoStatus, setVideoStatus] = useState(false)

  return (
    <Container>
      <IconLogo />

      {description ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}

      <Line className="separator" />

      <Wrapper>
        <Preview
          className={videoStatus ? 'played' : undefined}
          onClick={() => {
            setVideoStatus(true)
            if (videoRef.current) {
              // @ts-ignore
              videoRef.current.play()
            }
          }}
        >
          {image ? (
            <LazyLoadComponent>
              <Image {...image} />
            </LazyLoadComponent>
          ) : null}
          <Button dial={5}>
            <Play />
          </Button>
        </Preview>

        <Player
          className={videoStatus ? 'played' : undefined}
          controls
          controlsList="nodownload"
          muted
          preload="none"
          ref={videoRef}
        >
          <source src={URL} type="video/mp4" />
        </Player>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 6.875rem 0 13.75rem;
  padding: 6.25rem 10.556vw 0;
  position: relative;
  text-align: center;
  &:before {
    content: '';
    width: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark2};
    position: absolute;
    top: 0;
    bottom: 35.5vh;
    left: 0;
    z-index: -1;
  }

  > svg {
    width: auto;
    height: 3.75rem;
    fill: ${({ theme }) => theme.colors.variants.secondaryLight};
    margin-bottom: 1.875rem;
  }

  .separator {
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    margin-right: auto;
    margin-left: auto;
  }

  @media (max-width: 1199px) {
    margin: 5.625rem 0 9.375rem;
    padding: 5rem 1.875rem 0;
    &:before {
      bottom: 30vh;
    }
  }

  @media (max-width: 1023px) {
    margin: -3.75rem 0 7.5rem;
  }

  @media (max-width: 767px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    &:before {
      bottom: 7.5rem;
    }
  }
`

const Description = styled.div`
  max-width: 55rem;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3rem;
  font-weight: 500;
  line-height: 4rem;
  margin: 0 auto 1.875rem;

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.875rem;
  }
`

const Wrapper = styled.div`
  height: 71.1vh;
  background: ${({ theme }) => theme.colors.variants.neutralDark3};
  margin-top: 3.75rem;
  position: relative;

  @media (max-width: 1199px) {
    height: 60vh;
  }

  @media (max-width: 1023px) {
    height: 50vh;
  }

  @media (max-width: 767px) {
    height: 16.25rem;
  }
`

const Preview = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  transition: 0.3s ease-in-out;
  &.played {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.2;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Button = styled(FlexBox)`
  width: 5rem;
  height: 5rem;
  border: 0.125rem solid ${rgba(theme.colors.variants.neutralLight4, 0.4)};
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  &:hover {
    &:before {
      content: '';
      background: ${({ theme }) => theme.colors.variants.neutralDark2};
    }
    svg {
      stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    }
  }
  &:before {
    content: '';
    width: 4rem;
    height: 4rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    border-radius: 50%;
    position: absolute;
    top: 0.375rem;
    left: 0.375rem;
    transition: 0.2s ease-in-out;
  }

  svg {
    width: auto;
    height: 1.125rem;
    fill: none;
    stroke: ${({ theme }) => theme.colors.variants.secondaryLight};
    stroke-width: 2;
    position: relative;
    transition: 0.2s ease-in-out;
  }

  @media (max-width: 1023px) {
    width: 3.75rem;
    height: 3.75rem;
    &:before {
      width: 2.75rem;
      height: 2.75rem;
    }

    svg {
      height: 0.75rem;
    }
  }
`

const Player = styled.video`
  display: none;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  &.played {
    display: block;
  }
`
