import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Line } from 'app/components/Common/Line'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  cta?: ButtonProps
  description?: string
  firstImage?: ImageProps
  label?: string
  secondImage?: ImageProps
  title?: string
}

export const AdvancedParagraph = memo(function AdvancedParagraph({
  cta,
  description,
  firstImage,
  label,
  secondImage,
  title,
}: Props) {
  return (
    <Container row space="between" tag="section" wrap>
      <LeftSide>
        {label ? (
          <FadeInUp>
            <Label>{label}</Label>
          </FadeInUp>
        ) : null}
        {title ? (
          <FadeInUp>
            <Title className="intro-title">
              {title}
              <Line />
            </Title>
          </FadeInUp>
        ) : null}

        <FirstImage>
          {firstImage ? (
            <LazyLoadComponent>
              <Image {...firstImage} />
            </LazyLoadComponent>
          ) : null}
        </FirstImage>
      </LeftSide>
      <RightSide>
        <SecondImage>
          {secondImage ? (
            <LazyLoadComponent>
              <Image {...secondImage} />
            </LazyLoadComponent>
          ) : null}
        </SecondImage>

        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}
        {cta ? (
          <FadeInUp>
            <CTA variant="advanced" {...cta} />
          </FadeInUp>
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin: 13.75rem 0;
  &:before {
    content: '';
    width: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    top: 8.0625rem;
    bottom: 17.0625rem;
    left: 0;
    z-index: -1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    margin: 7.5rem 0;
    &:before {
      content: '';
      bottom: 9.375rem;
    }
  }

  @media (max-width: 1023px) {
    margin: 5.625rem 0;
    &:before {
      top: 0;
      bottom: 0;
    }
  }
`

const LeftSide = styled.div`
  width: 50%;
  padding: 18.1875rem 1.4vw 0 10.556vw;

  @media (max-width: 1199px) {
    padding: 14.375rem 0 0 1.875rem;
  }

  @media (max-width: 1023px) {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0;

    > div {
      width: 100%;
      order: 2;
      padding: 0 1.875rem;
      &:last-of-type {
        order: 1;
      }
    }
  }

  @media (max-width: 767px) {
    > div {
      padding-right: 1.25rem;
      padding-left: 1.25rem;
    }
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 500;
  line-height: 1.25rem;
  margin-bottom: 0.9375rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.5rem;
  font-weight: 500;
  line-height: 4.5rem;

  > div {
    margin-top: 2.125rem;
  }

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.875rem;

    > div {
      margin-top: 1.25rem;
    }
  }
`

const FirstImage = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin-top: 12.5rem;
  padding-bottom: 126%;
  position: relative;

  @media (max-width: 1199px) {
    margin-top: 7.5rem;
  }

  @media (max-width: 1023px) {
    margin: 0 0 3rem;
    padding-bottom: 50% !important;
  }
`

const RightSide = styled.div`
  width: 50%;
  padding: 0 10.556vw 0 8.15vw;

  @media (max-width: 1199px) {
    padding: 0 1.875rem 0 5.625rem;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding: 0 1.875rem 3.75rem;
  }

  @media (max-width: 767px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 1.875rem;
  margin-top: 8.0625rem;

  @media (max-width: 1199px) {
    font-size: 1rem;
    line-height: 1.75rem;
    margin-top: 5rem;
  }

  @media (max-width: 1023px) {
    margin-top: 2.5rem;
  }
`

const CTA = styled(Button)`
  margin-top: 3.75rem;
`

const SecondImage = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding-bottom: 131%;
  position: relative;

  @media (max-width: 1023px) {
    display: none;
  }
`
