import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  image?: ImageProps
  title?: string
  website?: string
}

export const Hotel = memo(function Hotel({ image, title, website }: Props) {
  return (
    <Container href={website || undefined} rel="noopener" target="_blank">
      {image ? (
        <LazyLoadComponent>
          <Image {...image} />
        </LazyLoadComponent>
      ) : null}
      {title ? (
        <Title className="hotel-title" dial={4} row>
          {title}
        </Title>
      ) : null}
    </Container>
  )
})

const Container = styled.a`
  width: calc(33.333% - 2.125rem);
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  margin-right: 2.125rem;
  overflow: hidden;
  padding-bottom: 17.1%;
  position: relative;
  &:hover {
    img {
      transform: scale(1.1);
    }
    .hotel-title {
      bottom: 1.5625rem;
    }
  }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    background: linear-gradient(
      ${rgba(theme.colors.variants.fadeDark, 0)},
      ${rgba(theme.colors.variants.fadeDark, 1)}
    );
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.2s ease-in-out !important;
  }

  @media (max-width: 1199px) {
    width: calc(33.333% - 1.25rem);
    margin-right: 1.25rem;
  }

  @media (max-width: 1023px) {
    width: 100%;
    margin: 0.75rem 0 0 0;
  }

  @media (max-width: 767px) {
    padding-bottom: 66%;
  }
`

const Title = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.875rem;
  position: absolute;
  bottom: 1.1875rem;
  right: 2.125rem;
  left: 2.125rem;
  transition: 0.2s ease-in-out;
  z-index: 2;
  &:before {
    content: '';
    width: 2rem;
    height: 0.0625rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    margin-right: 1rem;
    transform: translateY(-0.125rem);
  }

  @media (max-width: 1199px) {
    right: 1.875rem;
    left: 1.875rem;
  }

  @media (max-width: 767px) {
    right: 1.25rem;
    left: 1.25rem;
  }
`
