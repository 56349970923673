import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { IconLogo } from 'app/components/Icons'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  image?: ImageProps
  subtitle?: string
  title?: string
  url?: string
}

export const Service = memo(function Service({
  image,
  subtitle,
  title,
  url,
}: Props) {
  if (!url) {
    return null
  }

  return (
    <Container to={url}>
      {image ? (
        <LazyLoadComponent>
          <Image {...image} />
        </LazyLoadComponent>
      ) : null}
      {title ? <Title className="service-title">{title}</Title> : null}
      {subtitle ? (
        <Subtitle className="service-subtitle">{subtitle}</Subtitle>
      ) : null}

      <IconLogo />
    </Container>
  )
})

const Container = styled(Link)`
  width: calc(33.333% - 3.9375rem);
  padding-bottom: 34%;
  overflow: hidden;
  margin-right: 3.9375rem;
  position: relative;
  text-align: center;
  &:nth-of-type(1) {
    margin-top: 7.375rem;
  }
  &:nth-of-type(2) {
    margin-top: 3.625rem;
  }
  &:hover {
    &:before {
      opacity: 0.92;
    }
    &:after {
      opacity: 0;
    }
    img {
      transform: scale(1.1);
    }
    svg {
      opacity: 0.16;
      transform: translateX(-50%) scale(1);
    }
    .service-title {
      transform: translateY(-1.75rem);
    }
    .service-subtitle {
      opacity: 1;
      transform: none;
    }
  }
  &:before,
  &:after {
    content: '';
    backface-visibility: hidden;
    position: absolute;
    transition: 0.25s ease-in-out;
    z-index: 2;
  }
  &:before {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      ${rgba(theme.colors.variants.fadeDark, 0)},
      ${rgba(theme.colors.variants.fadeDark, 1)}
    );
    opacity: 0.7;
    top: 0;
    left: 0;
  }
  &:after {
    border: 0.0625rem solid
      ${({ theme }) => theme.colors.variants.neutralLight4};
    opacity: 0.4;
    top: 1.25rem;
    right: 1.25rem;
    bottom: 1.25rem;
    left: 1.25rem;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.25s ease-in-out !important;
  }

  svg {
    width: auto;
    height: 7rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight4};
    opacity: 0;
    position: absolute;
    bottom: 2.375rem;
    left: 50%;
    transform: translateX(-50%) scale(0);
    transition: 0.25s ease-in-out;
    z-index: 2;
  }

  @media (max-width: 1199px) {
    width: calc(33.333% - 3.125rem);
    margin-right: 3.125rem;
    padding-bottom: 38%;
  }

  @media (max-width: 1023px) {
    width: 100%;
    margin: 1.5rem 0 0 0 !important;
    padding-bottom: 33%;
    &:before {
      opacity: 0.92;
    }
    svg {
      opacity: 0.16;
      transform: translateX(-50%) scale(1);
    }
  }

  @media (max-width: 767px) {
    padding-bottom: 120%;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.3125rem;
  position: absolute;
  bottom: 3.375rem;
  right: 2.5rem;
  left: 2.5rem;
  transition: 0.25s ease-in-out;
  z-index: 3;

  @media (max-width: 1023px) {
    transform: translateY(-1.75rem);
  }
`

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.375rem;
  opacity: 0;
  position: absolute;
  bottom: 3.375rem;
  right: 2.5rem;
  left: 2.5rem;
  transition: 0.25s ease-in-out;
  transform: translateY(3.375rem);
  white-space: nowrap;
  z-index: 3;

  @media (max-width: 1023px) {
    opacity: 1;
    transform: none;
  }
`
