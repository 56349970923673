import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Line } from 'app/components/Common/Line'
import { IconLogo } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  items: ItemProps[]
  label?: string
  title?: string
}

export const RoomsPreview = memo(function RoomsPreview({
  items,
  label,
  title,
}: Props) {
  if (items.length < 1) {
    return null
  }

  return (
    <Container>
      <IconLogo />

      {label ? (
        <FadeInUp>
          <Label>{label}</Label>
        </FadeInUp>
      ) : null}
      {title ? (
        <FadeInUp>
          <Title>
            {title}
            <Line />
          </Title>
        </FadeInUp>
      ) : null}

      <Rooms row wrap>
        {items.map((item, index) => (
          <Item key={index} {...item} />
        ))}
      </Rooms>
    </Container>
  )
})

const Container = styled.section`
  margin: 13.75rem 0;
  padding: 0 10.556vw;
  text-align: center;

  > svg {
    width: 4.9375rem;
    height: auto;
    fill: ${({ theme }) => theme.colors.variants.neutralDark2};
    margin-bottom: 3.25rem;
  }

  @media (max-width: 1199px) {
    margin: 7.5rem 0 9.375rem;
    padding: 0 1.875rem;

    > svg {
      width: 3.75rem;
      margin-bottom: 1.875rem;
    }
  }

  @media (max-width: 1023px) {
    overflow: hidden;
    margin: 5.625rem 0 7.5rem;
  }

  @media (max-width: 767px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 600;
  line-height: 1.25rem;
  margin-bottom: 0.9375rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3rem;
  font-weight: 600;
  line-height: 4rem;

  > div {
    margin: 1.875rem auto 0;
  }

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.875rem;

    > div {
      margin-top: 1.25rem;
    }
  }
`

const Rooms = styled(FlexBox)`
  margin-right: -4rem;

  @media (max-width: 1023px) {
    margin-right: 0;
  }
`
