import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  className?: string
  image?: ImageProps
  languageCode: string
  style?: any
  title: string
  URL: string
  validity?: string
}

export const Slide = memo(function Slide({
  className,
  image,
  languageCode,
  style,
  title,
  URL,
  validity,
}: Props) {
  if (!title || !URL) {
    return null
  }

  return (
    <Container className={className} style={style} to={URL}>
      <Background>
        {image ? (
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        ) : null}
      </Background>
      <Wrapper>
        {validity ? <Validity>{validity}</Validity> : null}
        <Title>{title}</Title>
        <CTA
          className="offer-cta"
          label={useVocabularyData('find-out-more', languageCode)}
          noActions
          variant="advanced"
        />
      </Wrapper>
    </Container>
  )
})

const Container = styled(Link)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  &:hover {
    .offer-cta {
      &:before {
        width: 3.75rem;
        opacity: 0.5;
        margin-right: 1.25rem;
        margin-left: 1.9375rem;
        transform: none;
      }
    }
  }

  @media (max-width: 1023px) {
    flex-wrap: wrap;
  }
`

const Background = styled.div`
  width: calc(50% + 12.5vw);
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    width: calc(50% + 10vw);
  }

  @media (max-width: 1023px) {
    width: 100%;
    height: 40%;
    position: relative;
  }
`

const Wrapper = styled.div`
  width: 36.111vw;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  margin-left: auto;
  padding: 5.3125rem 5.125rem;
  position: relative;

  @media (max-width: 1199px) {
    width: 52vw;
  }

  @media (max-width: 1023px) {
    width: 100%;
    height: 60%;
    padding: 3rem 1.875rem;
  }

  @media (max-width: 767px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`

const Validity = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 1.875rem;
  margin-bottom: 1.125rem;

  @media (max-width: 1199px) {
    font-size: 1rem;
    margin-bottom: 0.875rem;
  }

  @media (max-width: 767px) {
    display: none;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 2.875rem;

  @media (max-width: 1199px) {
    font-size: 1.875rem;
    line-height: 2.4375rem;
  }
`

const CTA = styled(Button)`
  margin-top: 2.125rem;
`
