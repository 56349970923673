import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Line } from 'app/components/Common/Line'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  cta?: ButtonProps
  image?: ImageProps
  services: ServiceProps[]
  title?: string
  variant?: Variant
}

export const ServicesList = memo(function ServicesList({
  cta,
  image,
  services,
  title,
  variant = 'default',
}: Props) {
  if (services.length < 1) {
    return null
  }

  return (
    <Container dial={4} row stretch tag="section" variant={variant} wrap>
      <LeftSide>
        {image ? (
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        ) : null}
      </LeftSide>
      <RightSide>
        {title ? (
          <FadeInUp>
            <Title className="services-title">
              {title}
              <Line />
            </Title>
          </FadeInUp>
        ) : null}
        <Services className="services-list" row wrap>
          {services.map((item, index) => (
            <Service key={index} {...item} />
          ))}
        </Services>
        {cta ? (
          <FadeInUp>
            <CTA variant="advanced" {...cta} />
          </FadeInUp>
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)<ContainerProps>`
  margin: 13.75rem 0 6.875rem;
  padding: 8rem 0;
  &:before {
    content: '';
    height: 100%;
    width: 76%;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }

  ${({ variant }) => {
    switch (variant) {
      case 'dark':
        return css`
          &:before {
            background: ${theme.colors.variants.neutralDark2};
          }
          .services-title {
            color: ${theme.colors.variants.neutralLight4};
            > div {
              background: ${theme.colors.variants.neutralLight4};
            }
          }
          .services-list {
            max-width: none;
            > div {
              width: 100%;
              padding-right: 0;
              div {
                color: ${theme.colors.variants.neutralLight4};
              }
            }
          }
        `
    }
  }}

  @media (max-width: 1199px) {
    margin: 9.375rem 0 5rem;
    padding: 5.625rem 0;
  }

  @media (max-width: 1023px) {
    margin: 7.5rem 0 3.75rem;
    padding: 5.625rem 1.875rem;
    &:before {
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    padding: 3.75rem 1.25rem 5.625rem;
  }
`

const LeftSide = styled.div`
  width: calc(50% - 9.514vw);
  overflow: hidden;
  margin-right: 9.514vw;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    width: calc(50% - 4.375rem);
    margin-right: 4.375rem;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const RightSide = styled.div`
  width: 50%;
  padding: 7.4375rem 0;

  @media (max-width: 1199px) {
    padding: 5rem 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding: 0;
    text-align: center;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 2.875rem;
  margin-bottom: 0.4375rem;

  @media (max-width: 1199px) {
    font-size: 1.875rem;
    line-height: 2.4375rem;
  }

  @media (max-width: 1023px) {
    > div {
      margin-right: auto;
      margin-left: auto;
    }
  }
`

const Services = styled(FlexBox)`
  max-width: 34.375rem;

  @media (max-width: 1023px) {
    max-width: none;
  }
`

const CTA = styled(Button)`
  margin-top: 5.875rem;

  @media (max-width: 1199px) {
    margin-top: 4.375rem;
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'dark'
