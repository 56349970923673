import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Line } from 'app/components/Common/Line'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  services: ServiceProps[]
  title?: string
}

export const ServicesPreview = memo(function ServicesPreview({
  services,
  title,
}: Props) {
  if (services.length < 1) {
    return null
  }

  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title>
            {title}
            <Line />
          </Title>
        </FadeInUp>
      ) : null}
      <Services row wrap>
        {services.map((item, index) => (
          <Service key={index} {...item} />
        ))}
      </Services>
    </Container>
  )
})

const Container = styled.section`
  margin: 13.75rem 0;
  padding: 0 10.556vw;

  @media (max-width: 1199px) {
    margin: 9.375rem 0;
    padding: 0 1.875rem;
  }

  @media (max-width: 1023px) {
    margin: 7.5rem 0;
  }

  @media (max-width: 767px) {
    margin: 5.625rem 0;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`

const Title = styled.h2`
  max-width: 35.625rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.5rem;
  font-weight: 500;
  line-height: 4.5rem;

  > div {
    margin-top: 1.75rem;
  }

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.875rem;

    > div {
      margin-top: 1.25rem;
    }
  }
`

const Services = styled(FlexBox)`
  margin-right: -3.9375rem;

  @media (max-width: 1199px) {
    margin-right: -3.125rem;
  }

  @media (max-width: 1023px) {
    margin: 1.875rem 0 0 0;
  }
`
