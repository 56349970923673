import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Link } from 'gatsby'
import { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  image?: ImageProps
  title: string
  URL: string
}

export const Item = memo(function Item({ image, title, URL }: Props) {
  if (!title || !URL) {
    return null
  }

  return (
    <Container to={URL}>
      <Background className="item-bg">
        {image ? (
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        ) : null}
      </Background>

      <Title className="item-title" row>
        {title}
      </Title>
    </Container>
  )
})

const Container = styled(Link)`
  width: calc(33.333% - 4rem);
  margin: 6.25rem 4rem 0 0;
  position: relative;
  text-align: left;
  &:hover {
    &:before {
      left: 2.0625rem;
    }
    .item-bg {
      img {
        transform: scale(1.1);
      }
    }
    .item-title {
      &:before {
        width: 3.75rem;
      }
    }
  }
  &:before {
    content: '';
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    top: 6.5rem;
    right: -2.0625rem;
    bottom: 0;
    left: 50%;
    transition: 0.3s ease-in-out;
    z-index: -1;
  }

  @media (max-width: 1199px) {
    margin-top: 4.375rem;
  }

  @media (max-width: 1023px) {
    width: 100%;
    margin-right: 0;
  }

  @media (max-width: 767px) {
    margin-top: 3.75rem;
  }
`

const Background = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  padding-bottom: 116%;
  position: relative;

  img {
    width: 101%;
    height: 101%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
  }

  @media (max-width: 1023px) {
    padding-bottom: 33%;
  }

  @media (max-width: 767px) {
    padding-bottom: 50%;
  }
`

const Title = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.875rem;
  padding: 2.25rem 0 2.0625rem;
  &:before {
    content: '';
    width: 2rem;
    height: 0.0625rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark2};
    opacity: 0.4;
    margin-right: 1rem;
    transform: translateY(0.875rem);
    transition: 0.3s ease-in-out;
  }
`
