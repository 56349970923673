import styled from '@emotion/styled'
import { IconLogo } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Hotel, Props as HotelProps } from './Hotel'

export interface Props {
  hotels: HotelProps[]
  label?: string
  logo?: string
  title?: string
}

export const OtherHotels = memo(function OtherHotels({
  hotels,
  label,
  logo,
  title,
}: Props) {
  if (hotels.length < 1) {
    return null
  }
  return (
    <Container>
      <IconLogo />

      <Head dial={4} row wrap>
        {logo ? (
          <Logo
            alt="Albatros Centro Vacanze"
            src={logo}
            width="110"
            height="63"
          />
        ) : null}
        <Wrapper>
          {label ? <Label>{label}</Label> : null}
          {title ? <Title>{title}</Title> : null}
        </Wrapper>
      </Head>

      <Hotels row wrap>
        {hotels.map((item, index) => (
          <Hotel key={index} {...item} />
        ))}
      </Hotels>
    </Container>
  )
})

const Container = styled.section`
  overflow: hidden;
  position: relative;

  > svg {
    width: 20.278vw;
    height: auto;
    fill: ${({ theme }) => theme.colors.variants.neutralDark2};
    position: absolute;
    top: calc(50% - 2.778vw);
    right: -1.25rem;
    transform: translateY(-50%);
  }

  @media (max-width: 1023px) {
    > svg {
      display: none;
    }
  }
`

const Head = styled(FlexBox)`
  padding: 0 10.556vw;

  @media (max-width: 1199px) {
    padding: 0 1.875rem;
  }

  @media (max-width: 767px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`

const Logo = styled.img`
  margin-right: 3.125rem;

  @media (max-width: 1199px) {
    margin-right: 2.5rem;
  }
`

const Wrapper = styled.div`
  transform: translateY(0.3125rem);
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 500;
  line-height: 1.25rem;
  margin-bottom: 0.1875rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1rem;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 2.875rem;

  @media (max-width: 1199px) {
    font-size: 1.875rem;
    line-height: 2.4375rem;
  }
`

const Hotels = styled(FlexBox)`
  margin: 2.125rem -2.125rem 0 0;
  padding: 0 10.556vw;
  &:before {
    content: '';
    width: 100%;
    height: 50%;
    background: ${({ theme }) => theme.colors.variants.neutralDark2};
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  @media (max-width: 1119px) {
    margin-right: -1.25rem;
    padding: 0 1.875rem;
  }

  @media (max-width: 1023px) {
    margin-right: 0;
  }

  @media (max-width: 767px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`
